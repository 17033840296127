import React, { useEffect, useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { DividerSm } from "../misc/style";
import { PreviewImages, PreviewImagesReverse, previewAssets } from "./PreviewAssets";
import { DisplaysWrapper } from "./style";

const Displays: React.FC = () => {
  const { t } = useTranslation("leagueDisplays2017");
  const [current, setcurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setcurrent((prevCount) => {
        if (prevCount + 1 === previewAssets.length) {
          return 0;
        } else {
          return prevCount + 1;
        }
      });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <DisplaysWrapper>
      <header>
        <h2>{t("displays.heading.text")}</h2>
        <p>{t("displays.subheading.text")}</p>
      </header>
      <div className="previewDisplays">
        <div className="displayTall">
          <div className="flexCenter">
            <div className="previewWrapper previewTransitions">
              <PreviewImages current={current} />
            </div>
            <div className="previewWrapper previewTransitions">
              <PreviewImagesReverse current={current} />
            </div>
          </div>
        </div>
        <div className="displayWide">
          <div className="flexCenter">
            <div className="previewWrapper previewTransitions">
              <PreviewImages current={current} />
            </div>
            <div className="previewWrapper previewTransitions">
              <PreviewImagesReverse current={current} />
            </div>
          </div>
        </div>
      </div>
      <DividerSm />
    </DisplaysWrapper>
  );
};

export default Displays;
